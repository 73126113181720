import React, { useState, useEffect, useCallback } from 'react'
import { 
    Container,
    Box,
    ThemeProvider,
    Snackbar,
    IconButton,
    createTheme,
} from '@mui/material'
import style from './Dashboard.module.css'
import FilterBar from './FilterBar/FilterBar'
import CloseIcon from '@mui/icons-material/Close';
import { DashboardCategoryEng, DashboardCategoryThai, DashboardTag } from '../../interfaces/DashboardTag'
import { DataUpdateCycleThai, DataTypeThai, PortTypeThai, Sorting, DashboardTypeThai, DashboardType } from '../../interfaces/FilterTypes'
import { ProvinceObject, ProvinceThai } from '../../interfaces/Province'
import { SnackbarMessage } from '../../interfaces/Snackbar';
import { mergeThemes } from '../../theme/merge';
import { getUserAccount } from '../../utils/login';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken, selectAccessTokenError, selectAccessTokenStatus, selectExpirationAt, fetchAccessToken } from '../../redux/features/authentication/authenticationSlice';
import { AppDispatch } from '../../redux/store';
import { useTitle } from 'react-use';
import { useLocation, useHistory } from 'react-router-dom';
import { Location } from 'history';
import { selectDashboardMetadata, selectDashboardError, selectDashboardStatus, fetchDashboard } from '../../redux/features/dashboard/dashboardSlice';
import { STATUS } from '../../variable/enum';
import { DashboardGalleryCard } from '../../interfaces/DashboardMetadata';
import { filterDashboardMetadata, prepDynamicFilter, prepQueryList, readQueryParams } from '../../utils/dashboard';
import { dashboard_card, select_theme } from '../../theme/dashboard';
import { textbox_theme } from '../../theme/textbox'
import { register_textbox_theme } from '../../theme/register'
import DynamicFilterBar from './DynamicFilterBar/DynamicFilterBar';
import LogInPopUp from '../../components/shared/LogInPopUp/LogInPopUp';
import DashboardTiles from './DashboardTiles/DashboardTiles';
import DashboardRows from './DashboardRows/DashboardRows';
import { DisplayMode, isDisplayMode } from '../../interfaces/DisplayMode';
import { dashboardTypeItems, sortingTypeItems } from '../../variable/filterType'
import DashboardList from './DashboardList/DashboardList';

const dashboard_tag: DashboardTag[] = require('../../variable/dashboard_tag.json')
const INITIAL_DYNAMIC_FILTER = {
    dashboardTypeFilter: [],
    dataUpdateCycleFilter: [],
    dataTypeFilter: [
        {
            dataTypeThai: "รายจังหวัด",
            correspondingList: []
        },
        {
            dataTypeThai: "ภาพรวมประเทศ",
            correspondingList: []
        }
    ]
}

const Dashboard2 = ({location}: {location: Location<{category: DashboardCategoryEng, dashboardType: DashboardType}>}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [activeTag, setActiveTag] = useState<DashboardCategoryThai>('ทั้งหมด')
    const [searchText, setSearchText] = useState<string>('')
    const [dashboardType, setDashboardType] = useState<"" | DashboardTypeThai>("")
    const [dataUpdateCycle, setDataUpdateCycle] = useState<"" | DataUpdateCycleThai>("")
    const [dataType, setDataType] = useState<"" | DataTypeThai>("")
    const [portType, setPortType] = useState<"" | PortTypeThai>("")
    const [province, setProvince] = useState<"" | ProvinceThai>("")
    const [portName, setPortName] = useState<string>("")
    const [dynamicFilter, setDynamicFilter] = useState<any>(INITIAL_DYNAMIC_FILTER)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [sorting, setSorting] = useState<Sorting>("ความนิยม")
    const [offset, setOffset] = useState<number>(0)
    const [isParamsSet, setIsParamsSet] = useState<boolean>(false)
    const dispatch = useDispatch<AppDispatch>()
    const accessToken = useSelector(selectAccessToken)
    const accessTokenStatus = useSelector(selectAccessTokenStatus)
    const accessTokenError = useSelector(selectAccessTokenError)
    const expiresAt = useSelector(selectExpirationAt)
    const dashboardMetadatasFull = useSelector(selectDashboardMetadata)
    const dashboardError = useSelector(selectDashboardError)
    const dashboardStatus = useSelector(selectDashboardStatus)
    const [dashboardMetadatas, setDashboardMetadatas] = useState<DashboardGalleryCard[]>([])
    let uniqueTagList = dashboardMetadatasFull.length > 0 ? ["ทั้งหมด"].concat([...new Set(dashboardMetadatasFull.map(({tags}) => tags).flat())]) : dashboard_tag.map(({category_thai}) => category_thai)
    const [activeTagList, setActiveTagList] = useState<DashboardCategoryThai[]>(uniqueTagList)
    const mergedTheme = mergeThemes(textbox_theme, register_textbox_theme, dashboard_card, select_theme)
    const theme = createTheme(mergedTheme)
    const user = getUserAccount()
    // switching between DashboardTiles and DashboardRows
    const [displayMode, setDisplayMode] = useState<DisplayMode>('rows')

    // change site title
    useTitle(
        `Travel Link | Dashboard${
            activeTag === "ทั้งหมด" ? "" : " | " + activeTag
        }`
    )

    // handle redirect with state passed
    const history = useHistory()
    const changeUrl = useCallback((
        category: DashboardCategoryThai, 
        searchText: string, 
        dashboardType: "" | DashboardTypeThai,
        dataUpdateCycle: "" | DataUpdateCycleThai,
        dataType: "" | DataTypeThai,
        province: "" | ProvinceThai,
        portType: "" | PortTypeThai,
        portName: "" | string,
        displayMode: DisplayMode,
        sorting: Sorting) => {
        let query_list: string[] = prepQueryList(
            category, 
            searchText,
            dashboardType, 
            dataUpdateCycle,
            dataType,
            province,
            portType,
            portName,
            displayMode,
            sorting)
        
        if (query_list.length !== 0 ) {
            history.push("?" + query_list.join("&"))
        } else if(isParamsSet) {
            history.push("/dashboard")
        }
    },[dashboard_tag, history, isParamsSet])

    const useQuery = () => {
        const { search } = useLocation()

        return React.useMemo(() => new URLSearchParams(search), [search])
    }

    var all_query = useQuery()
    const getAllQuery = () => {
        let searchTextTmp = all_query.get("search")
        let activeTagTmp = all_query.get("category")
        let dashboardTypeTmp = all_query.get("dashboardType")
        let dataUpdateCycleTmp = all_query.get("dataUpdateCycle")
        let dataTypeTmp = all_query.get("dataType")
        let provinceTmp = all_query.get("province")
        let portTypeTmp = all_query.get("portType")
        let portNameTmp = all_query.get("portName")

        
        return {searchTextTmp, activeTagTmp, dashboardTypeTmp, dataTypeTmp, dataUpdateCycleTmp, provinceTmp, portTypeTmp, portNameTmp}
    }

    // category selected from NavBar, TagElement
    useEffect(() => {
        if (location.state) {
            if(location.state.category){
                let queryTag = dashboard_tag.find(({category_eng}) => category_eng === location.state.category) 
                if(queryTag){
                    setActiveTag(queryTag.category_thai)
                }
            } else {
                setActiveTag("ทั้งหมด")
            }
            if(location.state.dashboardType){
                let matchedDashboardType = dashboardTypeItems.find(({english}) => english === location.state.dashboardType)
                if(matchedDashboardType){
                    setDashboardType(matchedDashboardType.thai)
                }
            } else {
                setDashboardType("")
            }
        }
    }, [location])

    const handleGetDashboardMetadata = (accessToken: string) => {

        let {searchTextTmp, activeTagTmp, dashboardTypeTmp, dataTypeTmp, dataUpdateCycleTmp, provinceTmp, portTypeTmp, portNameTmp} = getAllQuery()
        if(dashboardStatus === STATUS.IDLE){
            dispatch(fetchDashboard(accessToken)).unwrap()
            .then((dashboardMetadatasFullTmp) => {

                let {searchText, activeTag, dashboardType, dataType, dataUpdateCycle, province, portType, portName}
                = readQueryParams(dashboardMetadatasFullTmp,searchTextTmp, activeTagTmp, dashboardTypeTmp, dataTypeTmp, dataUpdateCycleTmp, provinceTmp, portTypeTmp, portNameTmp)
                // set all filter variables that pass query params
                setSearchText(searchText)
                setSearchInput(searchText)
                setActiveTag(activeTag)
                setDashboardType(dashboardType)
                setDataType(dataType)
                setDataUpdateCycle(dataUpdateCycle)
                setProvince(province)
                setPortType(portType)
                setPortName(portName)
                let uniqueTagListTmp = ["ทั้งหมด"].concat([...new Set(dashboardMetadatasFullTmp.map(({tags}) => tags).flat())]) 
                setActiveTagList(uniqueTagListTmp)

                // first initialize of the dashboard metadatas
                let dashboardMetadatasTmp = filterDashboardMetadata(dashboardMetadatasFullTmp, searchText, activeTag, dashboardType, dataType, dataUpdateCycle, portType, province , portName, "ความนิยม" )
                setDashboardMetadatas(dashboardMetadatasTmp)
                let dynamicFilterTmp = prepDynamicFilter(dashboardMetadatasFullTmp, searchText, activeTag, dashboardType, dataType, dataUpdateCycle)
                setDynamicFilter(dynamicFilterTmp)
                setIsLoading(false)
                setIsParamsSet(true)
            })
            .catch((error) => {
                setErrorMessage("เกิดปัญหาบางอย่าง กรุณาลองใหม่")
            })
        } else if(dashboardStatus === STATUS.SUCCEEDED){

            let {searchText, activeTag, dashboardType, dataType, dataUpdateCycle, province, portType, portName}
            = readQueryParams(dashboardMetadatasFull,searchTextTmp, activeTagTmp, dashboardTypeTmp, dataTypeTmp, dataUpdateCycleTmp, provinceTmp, portTypeTmp, portNameTmp)
            // set all filter variables that pass query params
            setSearchText(searchText)
            setSearchInput(searchText)
            setActiveTag(activeTag)
            setDashboardType(dashboardType)
            setDataType(dataType)
            setDataUpdateCycle(dataUpdateCycle)
            setProvince(province)
            setPortType(portType)
            setPortName(portName)

            let dashboardMetadatasTmp = filterDashboardMetadata(dashboardMetadatasFull,searchText, activeTag, dashboardType, dataType, dataUpdateCycle, portType, province , portName, "ความนิยม" )
            setDashboardMetadatas(dashboardMetadatasTmp)
            let dynamicFilterTmp = prepDynamicFilter(dashboardMetadatasFull, searchText, activeTag, dashboardType, dataType, dataUpdateCycle )
            setDynamicFilter(dynamicFilterTmp)
            setIsLoading(false)
            setIsParamsSet(true)
        }
    }
     

    const fetchData = useCallback(async () => {
        setIsLoading(true)
                // first time getting dashboard metadata
                if(user.getIsLoggedIn()){
                    if(accessTokenStatus === STATUS.IDLE){
                        dispatch(fetchAccessToken())
                    } else if (accessTokenStatus === STATUS.SUCCEEDED) {
                            try {
                                if (expiresAt < Math.floor(Date.now() / 1000)) {                  
                                    dispatch(fetchAccessToken());
                                }
                                else{
                                    handleGetDashboardMetadata(accessToken)
                                }
                              } catch (error) {
                                // Handle error if any
                                console.log(error)
                              }
                    } 
                } else { //user not loggedIn
                    handleGetDashboardMetadata("")
                }
                
        }, [accessToken])

    useEffect(() => {
        fetchData()
    }, [fetchData, accessToken])

    useEffect(() => {
        if(isParamsSet){
            let displayModeTmp = all_query.get("displayMode")
            if(displayModeTmp && isDisplayMode(displayModeTmp)){
                setDisplayMode(displayModeTmp as DisplayMode)
            }

            let sortingTmp = all_query.get('sortBy')
            if(sortingTmp){
                let matchSorting = sortingTypeItems.find(({english}) => sortingTmp === english)
                if(matchSorting){
                    setSorting(matchSorting.thai)
                }
            }
        }
        
    },[isParamsSet])

    const handleTagChange = useCallback((category: DashboardCategoryThai) => {
        setActiveTag(category)
        setDashboardType("")
        setDataUpdateCycle("")
        setDataType("")
        setPortType("")
        setProvince("")
        setPortName("")
    },[ 
        setActiveTag,
        setDashboardType,
        setDataUpdateCycle,
        setDataType,
        setPortType,
        setProvince,
        setPortName])

    const handleReset = useCallback(() =>{
        handleTagChange(activeTag)
    },[activeTag, handleTagChange, setSearchText])

    const handleResetText = useCallback(() =>{
        setSearchText("")
        setSearchInput("")
        changeUrl(activeTag, "", dashboardType, dataUpdateCycle, dataType, province, portType, portName, displayMode, sorting)
    },[activeTag, handleTagChange, setSearchText, dashboardType, dataUpdateCycle, dataType, province, portType, portName])

    const handleSearchTextChange = useCallback((searchText: string) => {
        if(isParamsSet){
            setSearchText(searchText)
            changeUrl(activeTag, searchText, dashboardType, dataUpdateCycle, dataType, province, portType, portName, displayMode, sorting)
        }
    },[activeTag, dashboardType, dataUpdateCycle, dataType, province, portType, portName, setSearchText, changeUrl, isParamsSet])

    // Setup Snackbar stuff
    
    const [openSnackBar, setOpenSnackBar] = useState<boolean>(false)
    const [snackPack, setSnackPack] = React.useState<readonly SnackbarMessage[]>([]);
    const [messageInfo, setMessageInfo] = React.useState<SnackbarMessage | undefined>(
        undefined
    )
    const [bookmarkTimeStamp, setBookmarkTimeStamp] = useState<number>(0)

    const handleSnackbarClose = useCallback(() => {
        setOpenSnackBar(false)
    },[setOpenSnackBar])
    // update bookmark action to trigger the dashboard metadatas update (Rows only)
    const handleSetSnackBarOpen = useCallback(
        (bool: boolean) => {
          setOpenSnackBar(bool);
          setBookmarkTimeStamp(Date.now());
        },
        [setOpenSnackBar, setBookmarkTimeStamp]
      )

    // update dashboard metadata if bookmark happens (without triggering loading screen)

    useEffect(() => {
        // filter the rest
        setDashboardMetadatas(filterDashboardMetadata(dashboardMetadatasFull, searchText, activeTag, dashboardType, dataType, dataUpdateCycle, portType, province, portName, sorting))
    },[bookmarkTimeStamp])

    const snackbarAction = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
    useEffect(() => {

      if (snackPack.length && !messageInfo) {
            // Set a new snack when we don't have an active one
            setMessageInfo({ ...snackPack[0] });
            setSnackPack((prev) => prev.slice(1));
            setOpenSnackBar(true);
        } else if (snackPack.length && messageInfo && openSnackBar) {
            // Close an active snack when a new one is added
            setOpenSnackBar(false);
        }
    }, [snackPack, messageInfo, openSnackBar]);


    const handleExited = () => {
        setMessageInfo(undefined)
    }
    
    // delay searchInput
    const [searchInput, setSearchInput] = useState<string>('');

    useEffect(() => {
        let DELAY_MS = 400
        const delayFn = setTimeout(() => handleSearchTextChange(searchInput), DELAY_MS);
        return () => clearTimeout(delayFn);
    }, [searchInput]);

    
    
    useEffect(() => {
        if(isParamsSet){
            if(activeTag === "ทั้งหมด"){
                setDisplayMode('rows')
                setSorting('ความนิยม')
            } else {
                setDisplayMode('tiles')
            }
        }
    },[activeTag, isParamsSet])

    // update user interaction
    useEffect(() => {
        async function setData(): Promise<void> {
            return new Promise((resolve) => {
                setTimeout(() => {
                resolve()
                }, 500); // Simulating an asynchronous operation with a delay
            });
        }
        const handleSetData = async () => {
            setIsLoading(true)
            changeUrl(activeTag, searchText, dashboardType, dataUpdateCycle, dataType, province, portType, portName,displayMode, sorting)
            await setData()

            // clean up all bookmark
            let dashboardMetadatasFullTmp = [...dashboardMetadatasFull]

            // filter the rest
            let infoMetadataTmp = filterDashboardMetadata(dashboardMetadatasFullTmp, searchText, activeTag, dashboardType, dataType, dataUpdateCycle, portType, province, portName, sorting)
            setDashboardMetadatas(infoMetadataTmp)
            setDynamicFilter(prepDynamicFilter(dashboardMetadatasFullTmp, searchText, activeTag,dashboardType, dataType, dataUpdateCycle))
            setOffset(0)
            setIsLoading(false)
        }
        // if dashboardMetadatasFull has not been received, stop handleSetData
        if(isParamsSet){
            handleSetData()
        }
        
    },[ activeTag, searchText, dashboardType, dataUpdateCycle, dataType, portType, province, sorting, displayMode, portName])


    // Setup LogInPopUp
    const [openLoginPopUp, setOpenLoginPopUp] = useState<boolean>(false)
    const [redirectUrl, setRedirectUrl] = useState<string>('')

    const handleLoginPopUp = useCallback((link: string) => {
        setRedirectUrl(link)
        setOpenLoginPopUp(true)
    },[setRedirectUrl, setOpenLoginPopUp])
    
    const handleLoginPopUpFromBookmark = useCallback(() => {
        setRedirectUrl('/dashboard')
        setOpenLoginPopUp(true)
    },[setRedirectUrl, setOpenLoginPopUp])



  return (
    <Container>
        <LogInPopUp open={openLoginPopUp} setOpen={setOpenLoginPopUp} redirect_uri={redirectUrl} />
        <ThemeProvider theme={theme}>

            <Box className = {style.title}>สำรวจแดชบอร์ด</Box>
            <FilterBar 
            activeTag={activeTag}
            setActiveTag= {handleTagChange}
            searchText={searchInput}
            setSearchText={setSearchInput}
            activeTagList={activeTagList}
            handleResetText={handleResetText}
            />
            <DynamicFilterBar
            activeTag={activeTag}
            dashboardType={dashboardType}
            setDashboardType={setDashboardType}
            dataUpdateCycle={dataUpdateCycle}
            setDataUpdateCycle={setDataUpdateCycle}
            dataType={dataType}
            setDataType={setDataType}
            portType={portType}
            setPortType={setPortType}
            province={province}
            setProvince={setProvince}
            portName={portName}
            setPortName={setPortName}
            handleReset={handleReset}
            sorting={sorting}
            setSorting={setSorting}
            displayMode={displayMode}
            setDisplayMode={setDisplayMode}
            dynamicFilter={dynamicFilter} />

            {
                displayMode === 'rows' ?
                <Box>
                    <DashboardRows 
                    isLoading={isLoading}
                    dashboardMetadatas={dashboardMetadatas}
                    searchText={searchText}
                    activeTag={activeTag}
                    dashboardType={dashboardType}
                    dataUpdateCycle={dataUpdateCycle}
                    dataType={dataType}
                    portType={portType}
                    province={province}
                    portName={portName}
                    setActiveTag={setActiveTag}
                    setOpenSnackBar={handleSetSnackBarOpen}
                    setSnackPack={setSnackPack}
                    handleLoginPopUp={handleLoginPopUp}
                    handleLoginPopUpFromBookmark={handleLoginPopUpFromBookmark}
                    />

                </Box>
                :
                displayMode === "tiles" ?

                <DashboardTiles
                offset={offset}
                setOffset={setOffset}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                dashboardMetadatas={dashboardMetadatas}
                searchText={searchText}
                activeTag={activeTag}
                dashboardType={dashboardType}
                dataUpdateCycle={dataUpdateCycle}
                dataType={dataType}
                portType={portType}
                province={province}
                portName={portName}
                setActiveTag={setActiveTag}
                setOpenSnackBar={setOpenSnackBar}
                setSnackPack={setSnackPack}
                handleLoginPopUp={handleLoginPopUp}
                handleLoginPopUpFromBookmark={handleLoginPopUpFromBookmark}
                />

                :
                // displayMode === "list"
                <DashboardList 
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                dashboardMetadatas={dashboardMetadatas}
                searchText={searchText}
                setOpenSnackBar={setOpenSnackBar}
                setSnackPack={setSnackPack}
                handleLoginPopUp={handleLoginPopUp}
                handleLoginPopUpFromBookmark={handleLoginPopUpFromBookmark}
                />
            }

            


            

            <Snackbar
            key={messageInfo ? messageInfo.key : undefined}
            TransitionProps={{ onExited: handleExited }}
            message={messageInfo ? messageInfo.message : undefined}
            open={openSnackBar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            action={snackbarAction}/>
        </ThemeProvider>
        
    </Container>
  )
}

export default Dashboard2